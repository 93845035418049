.gps_ring {
    position: absolute;
    content: "";
    top: 29.3%;
    left: 50.7%;
    height: 140px;
    width: 140px;
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -webkit-animation: pulse 1.2s ease-out infinite;
    -webkit-box-sizing: border-box;
    transform: translate(-50%, -50%);
    animation: pulse 1.2s ease-out infinite;
    box-sizing: border-box;

    background: rgba(255, 152, 0, 0.4);
    border: 3px solid #ff9800;
    border-radius: 100%;
    pointer-events: none;
    will-change: transform
}

@-webkit-keyframes pulse {
    0% {
        -webkit-transform: translate(-50%,-50%) scale(0.1);
        transform: translate(-50%,-50%) scale(0.1);
        opacity: 1
    }

    100% {
        -webkit-transform: translate(-50%,-50%) scale(0.8);
        transform: translate(-50%,-50%) scale(0.8);
        opacity: .3
    }
}

@keyframes pulse {
    0% {
        -webkit-transform: translate(-50%,-50%) scale(0.1);
        transform: translate(-50%,-50%) scale(0.1);
        opacity: 1
    }

    100% {
        -webkit-transform: translate(-50%,-50%) scale(0.8);
        transform: translate(-50%,-50%) scale(0.8);
        opacity: .3
    }
}