
.oddRow {
    background-color: #fbfbfb!important;
}
.oddColumn{
    text-align: center;
    background-color: #f5c0ad!important;
}
.evenColumn{
    text-align: center;
    background-color: #a5c7a0!important;
}

.oddRow.oddColumn {
    background-color:  #f3baa5!important;
}

.oddRow.evenColumn {
    background-color: #a2d19b;
}

.virtual-table-cell {
    
    font-weight:600;
    box-sizing: border-box;
    padding: 16px;
    border-bottom: 1px solid #e8e8e8;
    border-right: 1px solid #e8e8e8;
    background: #FFF;
}
.footer{
    background-color: #dadada;
    border-top: 6px double #949090;
    border-right: 1px solid #949090;
}