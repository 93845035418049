body {
  margin:0px;
  padding:0px;
  box-sizing: border-box;
}

#components-layout-demo-side .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.ant-menu-inline-collapsed {
  width: auto;
}

.ant-layout-sider-zero-width-trigger {
  top: 0px
}


.ant-btn {
  margin-right: 8px;
  margin-bottom: 12px;
}
.ant-layout-header{
  height: 48px;
}


.dynamic-delete-button {
  cursor: pointer;
  position: relative;
  top: 4px;
  font-size: 24px;
  color: #999;
  transition: all 0.3s;
}

.dynamic-delete-button:hover {
  color: #777;
}

.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

.nameTag{
  cursor:pointer;
}