.wrapper {
    padding: 32px 16px 0;
    flex: 1;
    background-color: #f1f1f1;
    margin: -5px;
}

.profileBanner {
    padding: 42px;
    padding-bottom: 88px;
    margin: -37px;
    margin-bottom: -50px;
    background-color: #003366;
    color: #ffffff !important;
    position: relative;
}

.main {
    font-size: 15px;
    font-weight: bold;
    color: #ffffff !important;

}

.secondary {
    color: #ffffff !important;
}

.familyListCard {
    margin-left: 10px;
    margin-right: 10px;
}

.ant-card {
    border-radius: 7px;
    margin-bottom: 10px;
    -webkit-box-shadow: 3px 3px 15px -5px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 3px 3px 15px -5px rgba(0, 0, 0, 0.5);
    box-shadow: 3px 3px 15px -5px rgba(0, 0, 0, 0.5);
}

.ant-card-head {
    min-height: 0px;
    padding-right: 12px;
    padding-left: 12px;

}

.ant-card-head-title {
    padding: 4px 0;
}

.ant-card-extra {
    padding: 4px 0;

    float: right;
}

.ant-card-extra .ant-btn {
    margin: 0px;
}

.ant-card-body {
    padding: 8px;
}

.ant-descriptions-title {
    margin-bottom: 5px;
    font-weight: 700;
    font-size: 14px;
    text-align: center;
}

.contactNameTag {
    font-size: 15px;
    margin: 0;
    font-size: 14px;
    font-variant: tabular-nums;
    margin-right: 8px;
    background: #fafafa;
    border-radius: 3px;
    cursor: default;
    padding-left: 0px;
}

.ant-row .ant-form-item {
    margin-bottom: 12px;
}

.ant-avatar-icon {
    margin-right: 5px;
}

.overHeadRow {
    box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.1);
    margin-bottom: 10px !important;
    padding-top: 3px;

}

.noteTakerSpan::before {
    content: 'Yazar: ';
}

.noteTakerSpan {
    color: #bfbfbf;
    font-style: italic;
    display: inline-block;
    margin-left: 5px;
    text-transform: capitalize;
}

.attachment {
    border-radius: 4px;
    border: 1px solid #bfbfbf;
    display: inline-block;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: normal;

}

.attachmentName {
    font-size: 10px;
}

.ant-timeline-item-label {
    width: calc(30% - 12px) !important;
}

.ant-timeline-item-tail,
.ant-timeline-item-head {
    left: 30% !important;
}

.ant-timeline-item-content {
    left: calc(30% - 4px) !important;
    width: calc(70% - 14px) !important;
    padding-right: 21px;
}