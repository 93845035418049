.avatar-uploader>.ant-upload {
  width: 128px;
  height: 128px;
}

.steps-content {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 2px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding: 20px;
}

.steps-action {
  margin-top: 24px;
}

[data-theme="dark"] .steps-content {
  margin-top: 16px;
  border: 1px dashed #303030;
  background-color: rgba(255, 255, 255, 0.04);
  color: rgba(255, 255, 255, 0.65);
  padding: 80px 20px;
}


.ant-upload-text-icon {
  float: left
}

.ant-upload-list-item-name {
  text-align: left;
}

.ant-upload-select-picture-card i {
  color: #999;
  font-size: 32px;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

.ant-upload-select-picture-card {
  height: 96px !important;
  width: 96px !important;
}


.mapContainer {
  /* position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0; */
}